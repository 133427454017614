import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import auth from "./auth";
import { API_URL } from "../settings/config";

const baseURL = `${API_URL}`;

const useAxios = () => {
  let token = auth.getToken();

  let axiosInstance;

  if (token) {
    axiosInstance = axios.create({
      baseURL,
      headers: { Authorization: token },
    });

    axiosInstance.interceptors.request.use(async (req) => {
      const user = jwt_decode(token);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

      if (!isExpired) {
        return req;
      } else {
        auth.clearAppStorage();
        window.location.pathname = "/login";
        return req;
      }
    });
  } else {
    console.log("enterrrr");
    axiosInstance = axios.create({
      baseURL,
    });
  }

  return axiosInstance;
};

export default useAxios;
