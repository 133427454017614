import React, { useState } from "react";
import TotalBalance from "../components/PurchaseHistory/TotalBalance";
import Profile from "./Profile";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import WithdrawAccountList from "../components/Wallet/WithdrawHistory/WithdrawAccountList";
// import WithdrawBalance from "../../components/Wallet/WithdrawHistory/WithdrawBalance";
// import WithdrawHistoryNots from "../../components/Wallet/WithdrawHistory/WithdrawHistoryNots";
import History from "../components/PurchaseHistory/History";
import {
  getPurchaseData,
  getTotalPurchaseData,
} from "../redux/actions/userAction";

const PurchaseHistory = () => {
  const { user, profitData, totalProfitData } = useSelector(
    (state) => state.auth
  );
  const [dayValue, setDayValye] = useState("");

  console.log(totalProfitData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTotalPurchaseData({ id: user?.e_franchise_hub?.id }));
    dispatch(getPurchaseData({ id: user?.e_franchise_hub?.id, filter_op: "" }));
  }, [dispatch, user]);

  const handleChange = (e) => {
    console.log(e.target.value);
    setDayValye(e.target.value);
    dispatch(
      getPurchaseData({
        id: user?.e_franchise_hub?.id,
        filter_op: e.target.value,
      })
    );
  };
  let newTotalWithPrice =
    profitData.length > 0
      ? profitData?.reduce((total, item) => {
          return (total += parseFloat(item.total_amount_of_sells_product));
        }, 0)
      : "0";
  return (
    <Profile user={user}>
      <div className="w-panel withdraw">
        <TotalBalance totalSelesData={totalProfitData} />

        <History
          profitData={profitData}
          handleChange={handleChange}
          dayValue={dayValue}
          newTotalWithPrice={newTotalWithPrice}
        />
        {/* <WithdrawHistoryNots /> */}
      </div>
    </Profile>
  );
};

export default PurchaseHistory;
