import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/common/Scroll-top";
import PrivateRoute from "./components/common/PrivateRoute";
// import AccountActivation from "./pages/AccountActivation";
// import AddAccount from "./pages/AddAccount";
// import EFranchiseApply from "./pages/E-franchiseApply";
// import EditAccount from "./pages/EditAccount";
// import EditProfile from "./pages/EditProfile";
// import EHubApply from "./pages/EHubApply";
// import FindBmp from "./pages/FindBmp";
// import Home from "./pages/Home";
// import HowThisWork from "./pages/HowThisWork";
// import Login from "./pages/Login";
// import MyPanel from "./pages/MyPanel";
// import Referral from "./pages/Referral";
// import Reward from "./pages/Reward";
// import SaveAccount from "./pages/Wallet/SaveAccount";
// import Withdraw from "./pages/Wallet/Withdraw";
// import WithdrawHistory from "./pages/Wallet/WithdrawHistory";
// import YourProfit from "./pages/Wallet/YourProfit";
// import ForgetPassword from "./pages/ForgetPassword";
// import PasswordConfirm from "./pages/PasswordConfirm";
import ImportRetry from "./pages/importRetry";
import Loading from "./pages/loading";
import PurchaseHistory from "./pages/PurchaseHistory";
import OwnHubInfo from "./pages/OwnHubInfo";

const Home = lazy(() => ImportRetry(() => import("./pages/Home")));
const Login = lazy(() => ImportRetry(() => import("./pages/Login")));
const HowThisWork = lazy(() =>
  ImportRetry(() => import("./pages/HowThisWork"))
);
const FindBmp = lazy(() => ImportRetry(() => import("./pages/FindBmp")));
const MyPanel = lazy(() => ImportRetry(() => import("./pages/MyPanel")));
const Referral = lazy(() => ImportRetry(() => import("./pages/Referral")));
const YourProfit = lazy(() =>
  ImportRetry(() => import("./pages/Wallet/YourProfit"))
);
const SaveAccount = lazy(() =>
  ImportRetry(() => import("./pages/Wallet/SaveAccount"))
);
const AddAccount = lazy(() => ImportRetry(() => import("./pages/AddAccount")));
const EditAccount = lazy(() =>
  ImportRetry(() => import("./pages/EditAccount"))
);
const Withdraw = lazy(() =>
  ImportRetry(() => import("./pages/Wallet/Withdraw"))
);
const WithdrawHistory = lazy(() =>
  ImportRetry(() => import("./pages/Wallet/WithdrawHistory"))
);
const Reward = lazy(() => ImportRetry(() => import("./pages/Reward")));
const EditProfile = lazy(() =>
  ImportRetry(() => import("./pages/EditProfile"))
);
const ForgetPassword = lazy(() =>
  ImportRetry(() => import("./pages/ForgetPassword"))
);
const PasswordConfirm = lazy(() =>
  ImportRetry(() => import("./pages/PasswordConfirm"))
);
const EFranchiseApply = lazy(() =>
  ImportRetry(() => import("./pages/E-franchiseApply"))
);
const EHubApply = lazy(() => ImportRetry(() => import("./pages/EHubApply")));
const AccountActivation = lazy(() =>
  ImportRetry(() => import("./pages/AccountActivation"))
);

const Privacy = lazy(() => ImportRetry(() => import("./pages/Privacy")));
const Terms = lazy(() => ImportRetry(() => import("./pages/Terms")));
const Accessibility = lazy(() =>
  ImportRetry(() => import("./pages/Accessibility"))
);
const ContactUs = lazy(() => ImportRetry(() => import("./pages/ContactUs")));

const RouteApp = () => {
  return (
    <BrowserRouter>
      <ScrollTop />
      {/* <Wrapper> */}
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/how-this-work" element={<HowThisWork />} />
          <Route path="/find-bmp" element={<FindBmp />} />
          <Route
            path="/my-panel"
            element={
              <PrivateRoute>
                <MyPanel />
              </PrivateRoute>
            }
          />
          <Route
            path="/referral"
            element={
              <PrivateRoute>
                <Referral />
              </PrivateRoute>
            }
          />
          <Route
            path="/your-profit"
            element={
              <PrivateRoute>
                <YourProfit />
              </PrivateRoute>
            }
          />
          <Route
            path="/save-account"
            element={
              <PrivateRoute>
                <SaveAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-account"
            element={
              <PrivateRoute>
                <AddAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-account/:id"
            element={
              <PrivateRoute>
                <EditAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/withdraw"
            element={
              <PrivateRoute>
                <Withdraw />
              </PrivateRoute>
            }
          />
          <Route
            path="/withdraw-history"
            element={
              <PrivateRoute>
                <WithdrawHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/reward"
            element={
              <PrivateRoute>
                <Reward />
              </PrivateRoute>
            }
          />
          <Route
            path="/ehub/info"
            element={
              <PrivateRoute>
                <OwnHubInfo />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <PrivateRoute>
                <EditProfile />
              </PrivateRoute>
            }
          />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<PasswordConfirm />} />
          <Route path="/e-franchise-apply" element={<EFranchiseApply />} />
          <Route path="/e-hub-apply" element={<EHubApply />} />
          <Route path="/account/activation" element={<AccountActivation />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-of-service" element={<Terms />} />
          <Route path="/accessibility" element={<Accessibility />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/purchase-history" element={<PurchaseHistory />} />
          {/* </ScrollTop> */}
        </Routes>
      </Suspense>
      {/* </Wrapper> */}
    </BrowserRouter>
  );
};

export default RouteApp;
