import React from "react";
import Modal from "react-modal";
Modal.setAppElement("#root");
const ModalContainer = (props) => {
  return (
    <Modal
      id="modal_bg"
      isOpen={props.open}
      onRequestClose={props.closeModal}
      //   style={customStyles}
      contentLabel="Example Modal"
      className={"modal"}
      overlayClassName={
        "bg-black bg-opacity-30 fixed top-0 left-0 w-full h-full z-50 block"
      }
      shouldCloseOnOverlayClick={true}
    >
      {props.children}
    </Modal>
  );
};

export default ModalContainer;
