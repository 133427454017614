import React from "react";
import { useSelector } from "react-redux";
import MobileBar from "../components/Home/MobileBar";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
const Layout = ({ children }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Header user={user} />
      {children}

      <Footer />
      <MobileBar user={user} />
    </>
  );
};

export default Layout;
