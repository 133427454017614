import React from "react";
import moment from "moment";
import EmptyImg from "../../images/wallet/empty-withdraw.webp";

const History = ({ profitData, dayValue, handleChange, newTotalWithPrice }) => {
  return (
    <div className="mt-5">
      <div className="flex items-center justify-between">
        <h4 className="xl:text-xl lg:text-lg font-medium text-black">
          Purchase History
        </h4>
      </div>
      <div className="bg-white shadow-bmpshadow rounded-md p-4 mt-4">
        <div className="flex flex-wrap items-start justify-between gap-5 md:gap-0">
          <div className="card_text">
            <h4 className="text-black text-base font-medium font-archivo">
              Sales Amount
            </h4>
            <h6 className="text-base font-medium text-black text-opacity-70">
              Total Amount:{" "}
              <span className="text-black">£{newTotalWithPrice}</span>
              {/* Total Profit: <span className="text-black">£{monthValue}</span> */}
            </h6>
            {/* <div className="pt-5">
              <img src={graph} alt="" />
            </div> */}
          </div>
          <div className="card_btn sm:w-[20%] w-10/12">
            <div className="input-group">
              <div className="relative">
                <select
                  value={dayValue}
                  onChange={handleChange}
                  id="ourCountry"
                  className="border text-black text-opacity-70 border-[#0DC1D9] h-[36px] rounded-md md:text-sm text-base  focus:ring-1 focus:border-0 w-full pl-3"
                  placeholder="enter/select your bank area..."
                >
                  <option value={"all"}>All</option>
                  <option value={"seven"}>Last 7 days</option>
                  <option className="py-2" value={"month"}>
                    Last 30 days
                  </option>
                  <option className="py-2" value={"year"}>
                    Last year
                  </option>
                </select>
                <div
                  id="ourCountry"
                  className="absolute top-1/2 right-[2px] -translate-x-1/2 -translate-y-1/2"
                >
                  <svg
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.18014 4.81279C4.08179 4.915 3.91821 4.915 3.81986 4.81279L0.0772356 0.923344C-0.0755945 0.764518 0.0369648 0.5 0.25738 0.5L7.74262 0.500001C7.96303 0.500001 8.07559 0.764519 7.92276 0.923345L4.18014 4.81279Z"
                      fill="#0DC1D9"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 rounded-md shadow-bmpshadow">
        {profitData?.length > 0 ? (
          profitData?.reverse().map((item, index) => (
            <div className="table-item md:block flex" key={item?.id}>
              <ul
                className={`overview_list md:flex items-center px-4 py-3 bg-gradient-to-tl from-[#028FF0]/10 to-[#0DC1D9]/10 bg-opacity-80 rounded-tl-md rounded-tr-md ${
                  index > 0 ? "md:hidden" : ""
                }`}
              >
                <li>
                  <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                    Serial No.
                  </span>
                </li>
                <li>
                  <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                    Person Name
                  </span>
                </li>
                <li>
                  <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                    Number of Products
                  </span>
                </li>
                <li>
                  <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                    Total Amount
                  </span>
                </li>
                <li>
                  <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                    Date
                  </span>
                </li>
                <li>
                  <span className="text-black lg:text-base text-sm font-light text-opacity-70">
                    Download Invoice
                  </span>
                </li>
              </ul>
              <ul className="overview_list md:flex items-center mx-4 py-3 md:border-b border-[#9D9D9D]">
                <li>
                  <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
                    {index + 1}
                  </span>
                </li>
                <li>
                  <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
                    {item?.person_name}
                  </span>
                </li>
                <li>
                  <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
                    {item?.number_of_sells_product}
                    {/* {item?.created_at &&
                      moment(item?.created_at).format("YYYY-MM-DD")} */}
                  </span>
                </li>
                <li>
                  <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
                    {item?.total_amount_of_sells_product}
                  </span>
                </li>
                <li>
                  <span className="lg:text-base text-sm text-black font-normal text-opacity-80">
                    {item?.Date && moment(item?.Date).format("YYYY-MM-DD")}
                  </span>
                </li>
                <li>
                  {item?.invoice ? (
                    <a
                      className="lg:text-base text-sm text-black font-normal text-opacity-80 cursor-pointer"
                      href={`${item?.invoice}`}
                    >
                      Download
                    </a>
                  ) : (
                    "Not Available"
                  )}
                </li>
              </ul>
            </div>
          ))
        ) : (
          <div className="min-h-[500px] flex items-center justify-center">
            <div className="empty_text text-center">
              <img className={EmptyImg} alt="" />
              <p className="text-[#8d8d8d] lg:text-xl md:text-lg text-base font-normal font-archivo mt-4">
                Empty Purchase History
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default History;
