import auth from "../../utils/auth";
import {
  USER_ACTIVATION_ERROR,
  CLEAR_USER_ERROR,
  USER_ACTIVATION_SUCCESS,
  RESEND_EMAIL_USER_ID,
  RESEND_EMAIL_SUCCESS,
  SET_USER_DATA,
  SET_UNAUTHENTICATED,
  APPLY_MODAL_OPEN,
  LOADING_USER,
  USER_PROFILE_EDIT_SUCCESS,
  USER_PROFILE_EDIT_CLEAR,
  GET_COUNTRY,
  GET_HUB_ADDRESS,
  GET_FRANCHISE_ADDRESS,
  USER_ERROR,
  GET_FRANCHISE_META,
  FORM_SUBMIT_SUCCESS,
  FETCH_HOW_THIS_WORK,
  FETCH_TERMS_AND_CONDITIONS,
  FETCH_PRIVACY_POLICY,
  FETCH_SITE_META,
  FETCH_MAP_DATA,
  FETCH_PROFILT_DATA,
  FETCH_TOTAL_PROFILT_DATA,
  FETCH_EHUB_DATA,
} from "../types";

function getActivationIdLocalStorage() {
  return localStorage.getItem("activation_id")
    ? JSON.parse(localStorage.getItem("activation_id"))
    : null;
}

const initialState = {
  authenticated: false,
  loading: false,
  authError: null,
  activationUserId: getActivationIdLocalStorage(),
  activationError: null,
  activationSuccess: false,
  resendActivationSuccess: false,
  userAddress: [],
  passChangeMsg: "",
  passChangeLoading: false,
  profileUpdateSuccess: false,
  user: auth.getUserInfo(),
  applyModalOpen: false,
  profileEditSuccess: false,
  countries: [],
  hubAddress: [],
  franchiseAddress: [],
  franchiseMeta: {},
  submitSuccess: false,
  howThisWork: {},
  terms: {},
  privacy: {},
  siteMeta: {},
  mapData: [],
  profitData: [],
  totalProfitData: [],
  ownHubData: {},
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_USER:
      return { ...state, loading: true };
    case GET_FRANCHISE_META:
      return { ...state, franchiseMeta: action.payload, loading: false };
    case GET_COUNTRY:
      return { ...state, countries: action.payload, loading: false };
    case GET_HUB_ADDRESS:
      return { ...state, hubAddress: action.payload, loading: false };
    case GET_FRANCHISE_ADDRESS:
      return { ...state, franchiseAddress: action.payload, loading: false };
    case SET_USER_DATA:
      return { ...state, user: action.payload, loading: false };
    case USER_ACTIVATION_ERROR:
      return { ...state, activationError: action.payload, loading: false };
    case USER_ACTIVATION_SUCCESS:
      return { ...state, activationSuccess: true, loading: false };
    case RESEND_EMAIL_USER_ID:
      return { ...state, activationUserId: action.payload, loading: false };
    case APPLY_MODAL_OPEN:
      return { ...state, applyModalOpen: action.payload, loading: false };
    case FORM_SUBMIT_SUCCESS:
      return { ...state, submitSuccess: true, loading: false };
    case RESEND_EMAIL_SUCCESS:
      return { ...state, resendActivationSuccess: true, loading: false };
    case USER_PROFILE_EDIT_SUCCESS:
      return { ...state, profileEditSuccess: true, loading: false };
    case USER_PROFILE_EDIT_CLEAR:
      return { ...state, profileEditSuccess: false, loading: false };
    case USER_ERROR:
      return { ...state, authError: action.payload, loading: false };
    case CLEAR_USER_ERROR:
      return {
        ...state,
        authError: null,
        activationError: null,
        activationSuccess: false,
        resendActivationSuccess: false,
        loading: false,
      };
    case SET_UNAUTHENTICATED:
      auth.clearAppStorage();
      return {
        ...state,
        authenticated: false,
        user: {},
        loading: false,
      };
    case FETCH_HOW_THIS_WORK:
      return { ...state, howThisWork: action.payload, loading: false };
    case FETCH_TERMS_AND_CONDITIONS:
      return { ...state, terms: action.payload, loading: false };
    case FETCH_PRIVACY_POLICY:
      return { ...state, privacy: action.payload, loading: false };
    case FETCH_SITE_META:
      return { ...state, siteMeta: action.payload, loading: false };
    case FETCH_MAP_DATA:
      return { ...state, mapData: action.payload, loading: false };
    case FETCH_EHUB_DATA:
      return { ...state, ownHubData: action.payload, loading: false };
    case FETCH_PROFILT_DATA:
      return { ...state, profitData: action.payload, loading: false };
    case FETCH_TOTAL_PROFILT_DATA:
      return { ...state, totalProfitData: action.payload, loading: false };
    default:
      return state;
  }
}
