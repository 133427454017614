import React, { useEffect } from "react";
import PrivateInfo from "../components/FIndBmp/PrivateInfo";
import { GoogleApiWrapper } from "google-maps-react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { ehubDataAction } from "../redux/actions/userAction";
import moment from "moment/moment";

const OwnHubInfo = ({ google }) => {
  const { ownHubData, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ehubDataAction());
  }, [dispatch]);

  console.log(ownHubData);
  return (
    <Layout>
      <PrivateInfo google={google} ownHubData={ownHubData} />
      <div className="container mx-auto">
        <div className="lg:py-10 py-8">
          <h4 className="lg:text-2xl text-xl font-barlow font-semibold text-black">
            Your E-hub Details
          </h4>
          <p className="text-black font-barlow font-normal text-lg">
            {ownHubData?.office_address}
          </p>
          <div className="hub_data_list grid lg:grid-cols-2 md:grid-cols-3 gap-5 items-center">
            <ul className="flex flex-col gap-2 mt-4 lg:col-span-1 md:col-span-2">
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  E-Hub Area :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {ownHubData?.address}
                </p>
              </li>
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  Owner name :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {user?.user?.first_name} {user?.user?.last_name}
                </p>
              </li>
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  Owner email :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {user?.user?.email}
                </p>
              </li>
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  Phone Number :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {user?.user?.phone_number}
                </p>
              </li>
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  Space Description :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {user?.e_franchise_hub?.space_description}
                </p>
              </li>
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  Delivery Vehicles :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {user?.e_franchise_hub?.delivery_vehicles}
                </p>
              </li>
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  Delivery Man :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {user?.e_franchise_hub?.is_delivery_man ? "Yes" : "No"}
                </p>
              </li>
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  Contact Year :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {user?.e_franchise_hub?.contact_year}
                </p>
              </li>
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  Available From :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {moment(user?.e_franchise_hub?.available_from).format(
                    "MMM Do YY"
                  )}
                </p>
              </li>
              <li className="sm:flex items-start gap-4">
                <span className="text-black lg:text-lg text-base font-barlow font-semibold">
                  Estimated population :
                </span>
                <p className="text-black lg:text-lg text-base font-barlow font-medium">
                  {ownHubData?.population}
                </p>
              </li>
            </ul>
            <div className="bg_thumb sm:block hidden">
              <img
                className="w-[400px] h-[300px] mx-auto"
                src={user?.e_franchise_hub?.space_img}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBegeuqB-13cdF6JVwiTbAmR8PEXDYNjyE",
})(OwnHubInfo);
