import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import modal1 from "../../images/applyNowModal/applyModal1.webp";
import submitModal from "../../images/applyNowModal/submitModal.webp";
const ApplyModalForm = ({ onSubmit, closeModal, count, setcount }) => {
  // const [isEHub, setisEHub] = useState("");
  // console.log(isEHub);
  const { handleSubmit } = useForm({ defaultValue: "" });
  // const { register, handleSubmit, watch } = useForm({ defaultValue: "" });
  // console.log(watch("phone_number").length);
  return (
    <>
      <div
        className="bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:w-[600px] md:w-[400px] w-[300px] rounded-lg md:p-10 sm:p-6 p-4 z-50"
        id="modal_content"
      >
        <button
          onClick={closeModal}
          className="inline-flex justify-center items-center h-6 w-6 rounded-full bg-black bg-opacity-20 absolute top-2 right-2 z-50 cursor-pointer"
          id="em_close"
        >
          <svg
            className="w-3 h-3 fill-white"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.94694 3.98075C5.07462 4.09678 5.16748 4.18379 5.26033 4.27081C5.89292 4.90314 6.52552 5.53547 7.15811 6.1678C7.38445 6.39404 7.4657 6.64929 7.35543 6.95096C7.20454 7.36864 6.68221 7.52527 6.31078 7.27002C6.24694 7.22361 6.18891 7.1714 6.13668 7.11339C5.45766 6.43465 4.78444 5.76171 4.10542 5.08298C4.0706 5.04817 4.03578 5.01336 3.99515 4.97856C3.95453 5.01916 3.91971 5.05397 3.88489 5.08878C3.19426 5.77912 2.50363 6.46946 1.81301 7.1598C1.58667 7.38604 1.32551 7.46146 1.02952 7.35124C0.611665 7.19461 0.460771 6.6725 0.721933 6.30703C0.762558 6.24901 0.81479 6.1968 0.867022 6.14459C1.54604 5.46585 2.21926 4.78712 2.89828 4.11418C2.9331 4.07937 2.97953 4.05617 3.02596 4.01556C2.96792 3.95175 2.9331 3.91694 2.89247 3.87633C2.20185 3.18599 1.51702 2.50145 0.826397 1.81691C0.605861 1.59067 0.524611 1.32961 0.634879 1.02795C0.785772 0.610268 1.30809 0.453636 1.67952 0.708888C1.74336 0.755297 1.8014 0.807508 1.85363 0.865519C2.53265 1.54426 3.20587 2.21719 3.88489 2.89593C3.91971 2.93074 3.95453 2.96555 3.99515 3.00035C4.03578 2.95975 4.0706 2.92494 4.10542 2.89013C4.79605 2.19979 5.48667 1.50945 6.1773 0.81911C6.40364 0.592864 6.6648 0.517449 6.96078 0.627671C7.37864 0.784303 7.52954 1.30641 7.26837 1.67188C7.22775 1.7299 7.17552 1.78211 7.12329 1.83432C6.44427 2.51305 5.77105 3.19179 5.09203 3.86473C5.05721 3.91114 5.01078 3.93434 4.94694 3.98075Z"
              fill="white"
            />
          </svg>
        </button>
        <div className="e_modal_body">
          <div className="modal-thumb">
            <img className="mx-auto sm:block hidden" src={modal1} alt="" />
          </div>
          <form
            action=""
            className="sm:mt-6 "
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* {count === 1 && ( */}
            <div className="modal-text text-center pt-6">
              <h4 className="lg:text-xl text-lg text-black font-medium">
                British Market Place E-Franchisee & E-Hub Application
              </h4>
              <span className="text-black text-opacity-80 md:text-sm text-xs font-light">
                Choose & continue your process
              </span>

              <div className="input-btn flex flex-wrap justify-center items-center gap-3 mt-6">
                <Link
                  to="/e-franchise-apply"
                  onClick={closeModal}
                  // value="eFranchise"
                  // onClick={() => {
                  //   setisEHub("eFranchise");
                  //   setcount(2);
                  // }}
                  className="border border-[#028FF0] border-opacity-70 p-2 md:w-[150px] w-[120px] text-black rounded-md text-sm hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9] "
                >
                  E-Franchising
                </Link>
                <Link
                  to="/e-hub-apply"
                  onClick={closeModal}
                  // value="eHub"
                  className="border border-[#028FF0] border-opacity-70 p-2 md:w-[150px] w-[120px] text-black rounded-md text-sm hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9]"
                >
                  E-Hub
                </Link>
              </div>
            </div>
            {/* )} */}

            {/* {count === 2 && (
              <div className="modal-text text-center pt-6">
                <h4 className="lg:text-xl text-lg text-black font-medium">
                  Write your phone number
                </h4>
                <div className="mt-6 w-[80%] mx-auto">
                  <div className="input-group">
                    <input
                      {...register("phone_number", { required: true })}
                      type="text"
                      className="h-[42px] px-3 border border-[#0DC1D9] rounded-md w-full focus-visible:outline-none text-sm placeholder:text-sm text-[#999999]"
                      placeholder="enter your phone number..."
                    />
                  </div>
                  <div className="flex justify-end items-center gap-3 pt-6">
                    <button
                      onClick={() => setcount(1)}
                      type="button"
                      className="text-black text-sm  font-normal  w-[100px] py-2 border border-[#028FF0] border-opacity-70 rounded-md hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9]"
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      disabled={watch("phone_number").length < 8}
                      onClick={() => setcount(3)}
                      className="text-sm font-normal w-[100px] py-2 border border-[#028FF0] border-opacity-70 rounded-md hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9] disabled:bg-gray-300 "
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            )}
            {count === 3 && (
              <div className="modal-text text-center pt-6">
                <h4 className="lg:text-xl text-lg text-black font-medium">
                  Write Your Email Address
                </h4>
                <span className="text-black text-opacity-80 md:text-sm text-xs font-light">
                  Enter your email address we will sent you OTP to verify
                </span>
                <div className="mt-6 w-[80%] mx-auto">
                  <div className="input-group">
                    <input
                      {...register("email", { required: true })}
                      type="email"
                      className="h-[42px] px-3 border border-[#0DC1D9] rounded-md w-full focus-visible:outline-none text-sm placeholder:text-sm text-[#999999]"
                      placeholder="enter your email address..."
                    />
                  </div>
                  <div className="flex justify-end items-center gap-3 pt-6">
                    <button
                      onClick={() => setcount(2)}
                      type="button"
                      className="text-sm font-normal w-[100px] py-2 border border-[#028FF0] border-opacity-70 rounded-md hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9]"
                    >
                      Back
                    </button>
                    <button
                      disabled={
                        watch("email") ===
                        `[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$`
                      }
                      type="submit"
                      className="text-sm font-normal w-[100px] py-2 border border-[#028FF0] border-opacity-70 rounded-md hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9]"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
            {count === 4 && (
              <div className="modal-text text-center pt-6">
                <h4 className="lg:text-xl text-lg text-black font-medium">
                  Verification
                </h4>
                <span className="text-black text-opacity-80 md:text-sm text-xs font-light">
                  Enter OTP code sent to your email moiurinsmou@gmail.com
                </span>
                <p className="text-sm text-black font-medium">
                  Did’t receive an OTP ?
                  <button
                    type="button"
                    className="text-[#0DC1D9] uppercase cursor-pointer"
                  >
                    Resend
                  </button>
                </p>
                <div className="mt-6 w-[80%] mx-auto">
                  <div className="input-group flex justify-center items-center gap-3">
                    <input
                      type="text"
                      className="h-[42px] w-[42px] text-center inline-block px-3 border border-[#028FF0] rounded-md focus-visible:outline-1 focus-visible:outline-[#028FF0] text-sm placeholder:text-sm text-[#999999]"
                    />
                    <input
                      type="text"
                      className="h-[42px] w-[42px] text-center inline-block px-3 border border-[#028FF0] rounded-md focus-visible:outline-1 focus-visible:outline-[#028FF0] text-sm placeholder:text-sm text-[#999999]"
                    />
                    <input
                      type="text"
                      className="h-[42px] w-[42px] text-center inline-block px-3 border border-[#028FF0] rounded-md focus-visible:outline-1 focus-visible:outline-[#028FF0] text-sm placeholder:text-sm text-[#999999]"
                    />
                    <input
                      type="text"
                      className="h-[42px] w-[42px] text-center inline-block px-3 border border-[#028FF0] rounded-md focus-visible:outline-1 focus-visible:outline-[#028FF0] text-sm placeholder:text-sm text-[#999999]"
                    />
                    <input
                      type="text"
                      className="h-[42px] w-[42px] text-center inline-block px-3 border border-[#028FF0] rounded-md focus-visible:outline-1 focus-visible:outline-[#028FF0] text-sm placeholder:text-sm text-[#999999]"
                    />
                  </div>
                  <div className="flex justify-end items-center gap-3 pt-6">
                    <button
                      type="button"
                      className="text-sm font-normal w-[100px] py-2 border border-[#028FF0] border-opacity-70 rounded-md hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9]"
                      onClick={() => setcount(3)}
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setcount(5)}
                      type="submit"
                      className="text-sm font-normal w-[100px] py-2 border border-[#028FF0] border-opacity-70 rounded-md hover:text-white hover:bg-gradient-to-r from-[#028FF0] to-[#0DC1D9]"
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            )} */}
          </form>
          {count === 5 && (
            <div
              className="bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:w-[600px] md:w-[400px] w-[300px] rounded-lg p-10"
              id="modal_content"
            >
              <div className="e_modal_body relative">
                <div className="modal-thumb absolute  lg:bottom-1/4 md:bottom-[60%] bottom-[80%] left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <img className="mx-auto " src={submitModal} alt="" />
                </div>
                <div className="modal-text text-center pt-6">
                  <h4 className="lg:text-xl text-lg text-black font-medium">
                    Your Application has been Successfully Submited
                  </h4>
                  <span className="text-black text-opacity-80 md:text-sm text-xs font-light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magina
                    aliqua. Quis ipsum suspendise ultrces gravida. Risus commodo
                    viverra maecenas accumsan lacus vel facilisis
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplyModalForm;
