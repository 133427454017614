import React, { useState } from "react";

import { Map, InfoWindow, Marker } from "google-maps-react";

const PrivateInfo = ({ google, ownHubData }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(false);

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowInfoWindow(true);
  };

  const onMapClicked = (props) => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker(null);
    }
  };

  return (
    <div>
      <section className="min-h-[500px] relative">
        {ownHubData && (
          <Map
            onClick={onMapClicked}
            google={google}
            containerStyle={{
              position: "static",
              width: "100%",
              height: "100%",
            }}
            style={{
              width: "100%",
              height: "100%",
            }}
            center={ownHubData}
            initialCenter={ownHubData}
            zoom={14}

            // disableDefaultUI={true}
          >
            {/* <Marker position={{ lat: 37.762391, lng: -122.439192 }} />
      <Marker position={{ lat: 38.762391, lng: -122.439192 }} /> */}
            {ownHubData && (
              <Marker
                onClick={onMarkerClick}
                name={ownHubData?.address}
                title={ownHubData?.address}
                position={{
                  lat: parseFloat(ownHubData.lat),
                  lng: parseFloat(ownHubData.lng),
                }}
              />
            )}

            <InfoWindow marker={activeMarker} visible={showInfoWindow}>
              <div className="px-3 py-1">
                <h1 className="2xl:text-base xl:text-sm text-xs font-archivo font-normal">
                  {selectedPlace.name}
                </h1>
              </div>
            </InfoWindow>
          </Map>
        )}
      </section>
    </div>
  );
};

export default PrivateInfo;
