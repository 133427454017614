import {
  LOADING_WALLET,
  LOADING_CLEAR,
  USER_WALLET,
  WALLET_ERROR,
  MONTHLY_PROFIT,
  WITHDRAW_SUMMARY,
  USER_REFERRAL,
  USER_MONTHLY_REFERRAL,
  USER_SAVED_BANK_ACCOUNT,
  USER_WITHDRAW_HISTORY,
  USER_WITHDRAW_SUCCESS,
  USER_WITHDRAW_CLEAR,
  USER_REFERRAL_PERSON,
  USER_REFERRAL_REMINDER_SUCCESS,
  USER_REFERRAL_REMINDER_CLEAR,
  USER_SAVED_BANK_ACCOUNT_DETAILS,
  WALLET_ERROR_CLEAR,
  USER_YEARLY_PROFIT,
} from "../types";

const initialState = {
  loading: false,
  userWallet: {},
  monthlyProfit: 0,
  withdrawSummery: {},
  userWithDrawSuccess: false,
  withDrawDeleteSuccess: false,
  userReferral: {},
  referralPerson: [],
  referralReminderSuccess: false,
  userMonthlyReferral: [],
  userWithdrawHistory: {},
  saveBankAc: [],
  accountDetails: {},
  yearlyProfit: [],
  error: null,
};

export default function userWallet(state = initialState, action = {}) {
  switch (action.type) {
    case LOADING_WALLET:
      return { ...state, loading: true };
    case LOADING_CLEAR:
      return { ...state, loading: false };
    case USER_WALLET:
      return { ...state, userWallet: action.payload, loading: false };
    case MONTHLY_PROFIT:
      return { ...state, monthlyProfit: action.payload, loading: false };
    case WITHDRAW_SUMMARY:
      return { ...state, withdrawSummery: action.payload, loading: false };
    case USER_REFERRAL:
      return { ...state, userReferral: action.payload, loading: false };
    case USER_REFERRAL_PERSON:
      return { ...state, referralPerson: action.payload, loading: false };
    case USER_MONTHLY_REFERRAL:
      return { ...state, userMonthlyReferral: action.payload, loading: false };
    case USER_YEARLY_PROFIT:
      return { ...state, yearlyProfit: action.payload, loading: false };
    case USER_SAVED_BANK_ACCOUNT:
      return { ...state, saveBankAc: action.payload, loading: false };
    case USER_SAVED_BANK_ACCOUNT_DETAILS:
      return { ...state, accountDetails: action.payload, loading: false };
    case USER_WITHDRAW_HISTORY:
      return { ...state, userWithdrawHistory: action.payload, loading: false };
    case USER_WITHDRAW_SUCCESS:
      return { ...state, userWithDrawSuccess: true, loading: false };
    case USER_WITHDRAW_CLEAR:
      return { ...state, userWithDrawSuccess: false, loading: false };
    case USER_REFERRAL_REMINDER_SUCCESS:
      return { ...state, referralReminderSuccess: true, loading: false };
    case USER_REFERRAL_REMINDER_CLEAR:
      return { ...state, referralReminderSuccess: false, loading: false };
    case WALLET_ERROR:
      return { ...state, loading: false, error: action.payload };
    case WALLET_ERROR_CLEAR:
      return { ...state, loading: false, error: null };
    default:
      return state;
  }
}
