import React from "react";
import totalWithdraw from "../../images/wallet/withdraw/total.webp";
// import lastWithdraw from "../../images/wallet/withdraw/last.webp";

const TotalBalance = ({ totalSelesData }) => {
  return (
    <div className="tab-pane grid lg:grid-cols-3 gap-4">
      <div className="bg-white shadow-bmpshadow rounded-md p-4 max-w-[300px] mt-4">
        <div className="flex items-center">
          <div className="item-left w-[80%]">
            <h6 className="text-base font-medium text-black text-opacity-70">
              Total Seles Balance
            </h6>
            <p className="text-sm font-light">
              Estimated Balance:
              <span className="text-[#0DC1D9]">
                £{totalSelesData?.message?.total}
              </span>
            </p>
          </div>
          <div className="item-thumb w-[20%]">
            <img src={totalWithdraw} alt="" />
          </div>
        </div>
      </div>
      {/* <div className="bg-white shadow-bmpshadow rounded-md p-4 max-w-[300px] lg:mt-4">
        <div className="flex items-center">
          <div className="item-left w-[80%]">
            <h6 className="text-base font-medium text-black text-opacity-70">
              Last Withdraw
            </h6>
            <p className="text-sm font-light">
              Estimated Balance:
              <span className="text-[#0DC1D9]">
                £{userWithdrawHistory?.last_withdraw}
              </span>
            </p>
          </div>
          <div className="item-thumb w-[20%]">
            <img src={lastWithdraw} alt="" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TotalBalance;
