// Auth
export const LOADING_USER = "LOADING_USER";
export const USER_ERROR = "USER_ERROR";
export const USER_ACTIVATION_SUCCESS = "USER_ACTIVATION_SUCCESS";
export const USER_ACTIVATION_ERROR = "USER_ACTIVATION_ERROR";
export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";
export const RESEND_EMAIL_USER_ID = "RESEND_EMAIL_USER_ID";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";

export const USER_PROFILE_EDIT_SUCCESS = "USER_PROFILE_EDIT_SUCCESS";
export const USER_PROFILE_EDIT_CLEAR = "USER_PROFILE_EDIT_CLEAR";

// Wallet
export const LOADING_WALLET = "LOADING_WALLET";
export const LOADING_CLEAR = "LOADING_CLEAR";
export const USER_WALLET = "USER_WALLET";
export const WALLET_ERROR = "WALLET_ERROR";
export const WALLET_ERROR_CLEAR = "WALLET_ERROR_CLEAR";
export const MONTHLY_PROFIT = "MONTHLY_PROFIT";
export const WITHDRAW_SUMMARY = "WITHDRAW_SUMMARY";

export const USER_SAVED_BANK_ACCOUNT = "USER_SAVED_BANK_ACCOUNT";

export const USER_SAVED_BANK_ACCOUNT_DETAILS =
  "USER_SAVED_BANK_ACCOUNT_DETAILS";

export const USER_WITHDRAW_HISTORY = "USER_WITHDRAW_HISTORY";
export const USER_WITHDRAW_SUCCESS = "USER_WITHDRAW_SUCCESS";

export const USER_WITHDRAW_CLEAR = "USER_WITHDRAW_CLEAR";

// referral
export const USER_REFERRAL = "USER_REFERRAL";
export const USER_MONTHLY_REFERRAL = "USER_MONTHLY_REFERRAL";

export const USER_YEARLY_PROFIT = "USER_YEARLY_PROFIT";

export const USER_REFERRAL_PERSON = "USER_REFERRAL_PERSON";

export const USER_REFERRAL_REMINDER_SUCCESS = "USER_REFERRAL_REMINDER_SUCCESS";
export const USER_REFERRAL_REMINDER_CLEAR = "USER_REFERRAL_REMINDER_CLEAR";

// JOB
export const APPLY_MODAL_OPEN = "APPLY_MODAL_OPEN";

// Meta
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_HUB_ADDRESS = "GET_HUB_ADDRESS";
export const GET_FRANCHISE_ADDRESS = "GET_FRANCHISE_ADDRESS";
export const GET_FRANCHISE_META = "GET_FRANCHISE_META";

export const FORM_SUBMIT_SUCCESS = "FORM_SUBMIT_SUCCESS";

export const FETCH_HOW_THIS_WORK = "FETCH_HOW_THIS_WORK";
export const FETCH_TERMS_AND_CONDITIONS = "FETCH_TERMS_AND_CONDITIONS";
export const FETCH_PRIVACY_POLICY = "FETCH_PRIVACY_POLICY";
export const FETCH_SITE_META = "FETCH_SITE_META";
export const FETCH_MAP_DATA = "FETCH_MAP_DATA";

export const FETCH_EHUB_DATA = "FETCH_EHUB_DATA";

// E-Hub Profit
export const FETCH_PROFILT_DATA = "FETCH_PROFILT_DATA";
export const FETCH_TOTAL_PROFILT_DATA = "FETCH_TOTAL_PROFILT_DATA";
