import React, { useState } from "react";

import Sidebar from "../components/Profile/Sidebar";
import Layout from "./Layout";

const Profile = ({ children, user }) => {
  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  return (
    <Layout>
      <div className="e-profile">
        <div className="container mx-auto relative pt-4 lg:pt-0">
          {isSidebarOpen ? (
            ""
          ) : (
            <div className="e_top_bar sticky top-0 z-30 rounded-md bg-white shadow-bmpshadow px-3 py-2 lg:hidden w-full leading-3">
              <button
                onClick={() => setisSidebarOpen(!isSidebarOpen)}
                type="button"
                id="eMenuToggle"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </button>
            </div>
          )}
          <div className="flex gap-0 lg:gap-5">
            <div
              className={
                isSidebarOpen
                  ? "w-[250px] lg:static fixed top-0 left-0 min-h-full transition-all z-50 bg-white overflow-y-scroll rightShadow"
                  : "w-[250px] lg:static fixed top-0 -left-[250px] min-h-full z-50 rightShadow"
              }
            >
              <Sidebar user={user} />
            </div>
            <div
              onClick={() => setisSidebarOpen(false)}
              className={
                isSidebarOpen
                  ? "e_overlay fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 z-20  transition-all p-0 m-0"
                  : "hidden"
              }
            ></div>
            <div className="e_profile_right min-h-[600px]">{children}</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
