import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../images/header/logo.webp";
import { applyModalToggle } from "../../redux/actions/userAction";
import ModalContainer from "../common/ModalContainer";
import ApplyModalForm from "../Forms/ApplyModalForm";
const Header = ({ user }) => {
  const { applyModalOpen } = useSelector((state) => state.auth);
  // const [isModelOpen, setismodelOpen] = useState(false);
  const [count, setcount] = useState(1);
  const dispatch = useDispatch();
  const handleApplyNowModel = () => {
    // setismodelOpen(true);
    dispatch(applyModalToggle(true));
  };
  const closeModal = () => {
    // setismodelOpen(false);
    dispatch(applyModalToggle(false));
  };
  const onSubmit = (data) => {
    // if (data.e_business && count === 1) {
    //   setcount(2);
    // } else
    // if (data.phone_number && count === 2) {
    //   setcount(3);
    // } else if (data.email && count === 3) {
    //   setcount(4);
    // }
    // setcount(5);
    console.log(data);
  };
  return (
    <header className="md:py-5 py-3 shadow-bmpshadow">
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <Link to={"/"} className="logo">
            <img className="h-10" src={logo} alt="" />
          </Link>
          <div className="e-menu">
            <ul className="flex items-center lg:gap-8 gap-4">
              <li className="hidden lg:inline">
                <Link
                  to="/how-this-work"
                  className="2xl:text-xl xl:text-lg md:text-base text-sm text-black font-medium not-italic font-barlow"
                >
                  How this work
                </Link>
              </li>
              {user?.user && user?.user?.role === "HUB" && (
                <li className="hidden lg:inline">
                  <Link
                    to="/ehub/info"
                    className="2xl:text-xl xl:text-lg md:text-base text-sm text-black font-medium not-italic font-barlow"
                  >
                    Your Hub
                  </Link>
                </li>
              )}
              <li className="hidden lg:inline">
                <Link
                  to="/find-bmp"
                  className="2xl:text-xl xl:text-lg md:text-base text-sm text-black font-medium not-italic font-barlow"
                >
                  Find BMP
                </Link>
              </li>
              {!user?.user?.id ? (
                <>
                  <li>
                    <button
                      onClick={handleApplyNowModel}
                      type="button"
                      id="e_btn"
                      className="bg-gradient-to-r from-theme_color to-primary_color hover:from-primary_color hover:to-theme_color rounded-md text-white 2xl:text-lg 2xl:leading-[1.5] xl:text-base xl:leading-[1.5] text-sm leading-[1.5] font-medium font-barlow px-3 py-1 tracking-[.5px] transition-all"
                    >
                      Apply Now
                    </button>
                  </li>
                  <li className="hidden lg:inline ">
                    <Link to="/login" className="flex items-start gap-[6px]">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.9417 0H16.0549C16.1394 0.0187991 16.2224 0.0446552 16.3077 0.0564046C16.8102 0.123768 17.3181 0.157445 17.8136 0.256924C21.5289 1.00341 24.5758 2.85903 26.887 5.86376C29.3133 9.02123 30.305 12.6173 29.9191 16.5753C29.6577 19.2519 28.7451 21.713 27.1117 23.8514C23.5309 28.5371 18.7676 30.5517 12.9195 29.871C10.1802 29.5522 7.72729 28.4431 5.58669 26.6924C2.70177 24.3339 0.9032 21.3245 0.234801 17.6532C0.138532 17.1276 0.0782669 16.5949 0 16.0654V13.9505C0.0430468 13.6137 0.0821801 13.2753 0.130706 12.9393C0.55413 10.0411 1.69604 7.45621 3.60967 5.24183C5.94359 2.5418 8.86764 0.855364 12.3881 0.228726C12.9023 0.13943 13.4228 0.0759798 13.9417 0ZM3.78734 22.4728C5.70435 19.7051 8.28951 17.8626 11.5428 16.9451C8.41839 14.6437 8.28142 10.4695 10.548 7.963C11.6178 6.7868 13.1097 6.0829 14.6971 6.00552C16.2844 5.92815 17.8376 6.48361 19.0165 7.5502C20.2688 8.67501 20.9356 10.099 20.9881 11.78C21.0546 13.9231 20.1592 15.6182 18.4475 16.9404C21.7238 17.8576 24.2933 19.7109 26.2139 22.4775C29.6976 17.3861 29.3337 9.74892 24.0068 4.95827C18.5751 0.0720671 10.3766 0.453529 5.39494 5.52224C0.348288 10.663 0.663703 18.0855 3.78734 22.4728ZM4.73671 23.7519C10.0776 30.1115 19.9831 30.059 25.2528 23.7519C22.8461 20.119 19.4994 18.0722 15.1102 18.0338C10.6365 17.9986 7.21386 20.0391 4.73671 23.7519ZM14.9897 16.5002C16.1783 16.5037 17.3199 16.0359 18.1648 15.1992C19.0096 14.3625 19.489 13.2249 19.4979 12.0354C19.5213 9.56719 17.4856 7.51261 15.0092 7.50712C13.8149 7.50919 12.67 7.98466 11.8252 8.82949C10.9803 9.67432 10.5042 10.8197 10.5011 12.015C10.5042 13.2043 10.9778 14.344 11.8185 15.1847C12.6591 16.0254 13.7982 16.4987 14.9865 16.5009L14.9897 16.5002Z"
                          fill="url(#paint0_linear_702_1214)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_702_1214"
                            x1="15"
                            y1="0"
                            x2="15"
                            y2="30"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#028FF0" />
                            <stop offset="1" stopColor="#0DC1D9" />
                          </linearGradient>
                        </defs>
                      </svg>

                      <div className="ml-1 2xl:text-xl xl:text-lg md:text-base text-sm text-black font-medium not-italic font-barlow">
                        Login
                      </div>
                    </Link>
                  </li>
                </>
              ) : (
                <li className="hidden lg:inline ">
                  <Link to="/my-panel" className="flex items-start">
                    {user?.profile_pic ? (
                      <img
                        src={user?.profile_pic}
                        className=" h-8 w-8 mr-2  rounded-full"
                        alt="profile pic"
                      />
                    ) : (
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.9417 0H16.0549C16.1394 0.0187991 16.2224 0.0446552 16.3077 0.0564046C16.8102 0.123768 17.3181 0.157445 17.8136 0.256924C21.5289 1.00341 24.5758 2.85903 26.887 5.86376C29.3133 9.02123 30.305 12.6173 29.9191 16.5753C29.6577 19.2519 28.7451 21.713 27.1117 23.8514C23.5309 28.5371 18.7676 30.5517 12.9195 29.871C10.1802 29.5522 7.72729 28.4431 5.58669 26.6924C2.70177 24.3339 0.9032 21.3245 0.234801 17.6532C0.138532 17.1276 0.0782669 16.5949 0 16.0654V13.9505C0.0430468 13.6137 0.0821801 13.2753 0.130706 12.9393C0.55413 10.0411 1.69604 7.45621 3.60967 5.24183C5.94359 2.5418 8.86764 0.855364 12.3881 0.228726C12.9023 0.13943 13.4228 0.0759798 13.9417 0ZM3.78734 22.4728C5.70435 19.7051 8.28951 17.8626 11.5428 16.9451C8.41839 14.6437 8.28142 10.4695 10.548 7.963C11.6178 6.7868 13.1097 6.0829 14.6971 6.00552C16.2844 5.92815 17.8376 6.48361 19.0165 7.5502C20.2688 8.67501 20.9356 10.099 20.9881 11.78C21.0546 13.9231 20.1592 15.6182 18.4475 16.9404C21.7238 17.8576 24.2933 19.7109 26.2139 22.4775C29.6976 17.3861 29.3337 9.74892 24.0068 4.95827C18.5751 0.0720671 10.3766 0.453529 5.39494 5.52224C0.348288 10.663 0.663703 18.0855 3.78734 22.4728ZM4.73671 23.7519C10.0776 30.1115 19.9831 30.059 25.2528 23.7519C22.8461 20.119 19.4994 18.0722 15.1102 18.0338C10.6365 17.9986 7.21386 20.0391 4.73671 23.7519ZM14.9897 16.5002C16.1783 16.5037 17.3199 16.0359 18.1648 15.1992C19.0096 14.3625 19.489 13.2249 19.4979 12.0354C19.5213 9.56719 17.4856 7.51261 15.0092 7.50712C13.8149 7.50919 12.67 7.98466 11.8252 8.82949C10.9803 9.67432 10.5042 10.8197 10.5011 12.015C10.5042 13.2043 10.9778 14.344 11.8185 15.1847C12.6591 16.0254 13.7982 16.4987 14.9865 16.5009L14.9897 16.5002Z"
                          fill="url(#paint0_linear_702_1214)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_702_1214"
                            x1="15"
                            y1="0"
                            x2="15"
                            y2="30"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#028FF0" />
                            <stop offset="1" stopColor="#0DC1D9" />
                          </linearGradient>
                        </defs>
                      </svg>
                    )}

                    <div className="ml-1 2xl:text-xl xl:text-lg md:text-base text-sm text-black font-medium not-italic font-barlow">
                      {user?.user?.first_name}
                    </div>
                  </Link>
                </li>
              )}

              {/* <li className="hidden lg:inline">
                <button type="button">
                  <Link to="/my-panel">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9417 0H16.0549C16.1394 0.0187991 16.2224 0.0446552 16.3077 0.0564046C16.8102 0.123768 17.3181 0.157445 17.8136 0.256924C21.5289 1.00341 24.5758 2.85903 26.887 5.86376C29.3133 9.02123 30.305 12.6173 29.9191 16.5753C29.6577 19.2519 28.7451 21.713 27.1117 23.8514C23.5309 28.5371 18.7676 30.5517 12.9195 29.871C10.1802 29.5522 7.72729 28.4431 5.58669 26.6924C2.70177 24.3339 0.9032 21.3245 0.234801 17.6532C0.138532 17.1276 0.0782669 16.5949 0 16.0654V13.9505C0.0430468 13.6137 0.0821801 13.2753 0.130706 12.9393C0.55413 10.0411 1.69604 7.45621 3.60967 5.24183C5.94359 2.5418 8.86764 0.855364 12.3881 0.228726C12.9023 0.13943 13.4228 0.0759798 13.9417 0ZM3.78734 22.4728C5.70435 19.7051 8.28951 17.8626 11.5428 16.9451C8.41839 14.6437 8.28142 10.4695 10.548 7.963C11.6178 6.7868 13.1097 6.0829 14.6971 6.00552C16.2844 5.92815 17.8376 6.48361 19.0165 7.5502C20.2688 8.67501 20.9356 10.099 20.9881 11.78C21.0546 13.9231 20.1592 15.6182 18.4475 16.9404C21.7238 17.8576 24.2933 19.7109 26.2139 22.4775C29.6976 17.3861 29.3337 9.74892 24.0068 4.95827C18.5751 0.0720671 10.3766 0.453529 5.39494 5.52224C0.348288 10.663 0.663703 18.0855 3.78734 22.4728ZM4.73671 23.7519C10.0776 30.1115 19.9831 30.059 25.2528 23.7519C22.8461 20.119 19.4994 18.0722 15.1102 18.0338C10.6365 17.9986 7.21386 20.0391 4.73671 23.7519ZM14.9897 16.5002C16.1783 16.5037 17.3199 16.0359 18.1648 15.1992C19.0096 14.3625 19.489 13.2249 19.4979 12.0354C19.5213 9.56719 17.4856 7.51261 15.0092 7.50712C13.8149 7.50919 12.67 7.98466 11.8252 8.82949C10.9803 9.67432 10.5042 10.8197 10.5011 12.015C10.5042 13.2043 10.9778 14.344 11.8185 15.1847C12.6591 16.0254 13.7982 16.4987 14.9865 16.5009L14.9897 16.5002Z"
                        fill="url(#paint0_linear_702_1214)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_702_1214"
                          x1="15"
                          y1="0"
                          x2="15"
                          y2="30"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#028FF0" />
                          <stop offset="1" stopColor="#0DC1D9" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Link>
                </button>
              </li> */}
              <ModalContainer open={applyModalOpen} closeModal={closeModal}>
                <ApplyModalForm
                  onSubmit={onSubmit}
                  closeModal={closeModal}
                  count={count}
                  setcount={setcount}
                />
              </ModalContainer>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
